/* RESET */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
html {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    background: linear-gradient(185deg, #eef8f4, #f1e9e0);
    width: 100%;
    overflow-x: hidden;
}

main {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

button:hover {
    background-color: transparent;
}

a {
    text-decoration: none;
    color: #1c4677;
    padding: 5px;
    transition: border-color 0.3s ease;
}

a:hover {
    border-color: #1c467767;
    cursor: pointer;
}

nav,
footer {
    display: grid;
    place-content: center;
    text-align: center;
    width: 100%;
    flex-shrink: 0;
}

footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(135deg, #2c3e50, #34495e);
    color: whitesmoke;
    box-shadow: inset 0px 8px 10px -10px rgba(0, 0, 0, 0.8), 0px -8px 10px -10px rgba(0, 0, 0, 0.8);
}

button {
    padding: 10px 16px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

button:hover {
    background-color: #c540b1;
    transition: background 0.5s ease-in-out;
    transform: translateY(-2px);
}

button:active {
    transform: scale(0.9);
    transition: background 0.5s ease-in-out;
}

.menu li {
    flex: 1;
}


.container-line-home {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #00000018;
    margin: 1rem 0;
}

.my-slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background: transparent;
    box-sizing: border-box;
}

.my-slider-img {
    width: 20rem;
    height: auto;
    border-radius: 12px;
    border: 2px solid #ffffff;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

    -webkit-user-select: none;
    user-select: none;
}

.my-slider-img:hover {
    border: 1px solid #fff2ff;
    cursor: grab;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.my-slider-img:active {
    border: 1px solid #17ff74;
    cursor: grabbing;
}

.swiper-pagination-bullet {
    background-color: #495057;
    opacity: 0.7;
    transition: background-color 0.3s ease-in-out;
}

.swiper-pagination-bullet-active {
    background-color: #212529;
    opacity: 1;
}

.swiper-button-prev,
.swiper-button-next {
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5%;
    opacity: 0.5;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
    color: #00699d;
    transform: scale(1.1);
}

.swiper-scrollbar-drag {
    background: #495057;
    border-radius: 10px;
    transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.swiper-scrollbar-drag:hover {
    background: #212529;
    transform: scale(1.1);
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.container-skills {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    place-items: start;
    padding: 1rem;
}

.logo-skills {
    display: flex;
    width: 7rem;
    margin: 0.1rem;
    height: 2.4rem;
    border: 1px solid transparent;
}

.logo-skills:hover {
    border: 1px solid #f717ff;
    cursor: pointer;
}

/* SMALL SCREEN --------------------------------------------------------- MOBILE */
@media screen and (min-width: 320px) {
    header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: linear-gradient(135deg, #2c3e50, #34495e);
        color: whitesmoke;
        font-size: 15px;
        padding: 10px;
        box-shadow: 0px 5px 5px 0px rgba(86, 85, 85, 0.378);
    }

    header h1 {
        display: flex;
        font-size: 17px;
        font-weight: normal;
    }

    .social-icons {
        display: flex;
        margin: 2px;
    }

    .social-icons a {
        color: rgb(255, 249, 255);
        font-size: 2rem;
        margin: 0.1rem;
    }

    .social-icons a:hover {
        color: rgb(52, 243, 201);
        transform: scale(1.1);
        transition: ease-out 0.3s;
    }

    .menu {
        list-style-type: none;
        display: flex;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        justify-content: space-around;
        margin: 0;
        box-shadow: 0px -0.125rem 0.625rem rgba(0, 0, 0, 0.1);
        z-index: 1;
    }

    .menu li a {
        text-decoration: none;
        width: 100%;
        font-size: 0.6rem;
        padding: 1.8rem 0;
        margin: 0;
        transition: background-color 0.3s ease-in, border-color 0.3s ease-in;
        background: rgba(255, 255, 255, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(92, 92, 92, 0.5);
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
        -webkit-backdrop-filter: blur(0.8rem);
        backdrop-filter: blur(0.8rem);
    }

    .menu li a:hover {
        background: linear-gradient(to right, #598dc7, #7fb0e1);
        color: white;
    }

    iframe {
        width: 100%;
        height: 100%;
        border: dashed rgba(128, 128, 128, 0.053) 0.1px;
        border-radius: 15px;
    }

    .container-travel-map,
    .container-DJ {
        display: flex;
        width: 100%;
        margin: 0 auto;
        height: 100vh;
    }

    h2 {
        display: grid;
        margin: 2rem;
        font-weight: normal;
        font-size: 22px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    h3 {
        font-size: 2rem;
        text-align: center;
        padding: 1rem;
        margin: 1rem 0;
        display: inline-flex;
    }

    .column {
        margin: 1rem 2rem;
        column-count: 1;
    }

    h4 {
        padding: 0.5rem;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        font-size: 0.7rem;
    }

    h5 {
        padding: 1.5rem;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 1.3rem;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }

    h6 {
        font-size: 0.8rem;
        margin: 1.2rem;
    }

    .btn {
        width: 20rem;
        font-size: 1.1rem;
        padding: 1.5rem 1.5rem;
        border-radius: 8px;
        margin: 0.1rem;
        transition: background-color 0.3s ease, border-color 0.3s ease;
        background: linear-gradient(to top, rgba(234, 234, 234, 0.7), rgba(255, 255, 255, 0.7)),
            linear-gradient(to bottom, rgba(219, 219, 219, 0.7), rgba(255, 255, 255, 0.7));
        color: #1c4777;
        display: flex;
        justify-content: flex-start;
        border: 1px solid rgba(92, 92, 92, 0.5);
    }

    .btn:hover {
        background: linear-gradient(to right, #598dc7, #7fb0e1);
        color: white;
        transform: scale(1.01);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    }

    .container-web-projects {
        width: 100%;
        display: grid;
        place-items: center;
        margin-bottom: 2rem;
    }

    .container-web-projects::before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #00000018;
        margin-bottom: 1rem;
    }
}

.container-online-users {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 2rem;
    border-radius: 10px;
    margin-bottom: 5rem;
    width: 90%;
}

.container-online-users h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.container-online-users ul {
    list-style-type: none;
    padding: 0;
}

.container-online-users li {
    margin-bottom: 1rem;
    padding: 0.5rem;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.container-online-users li p {
    margin: 0;
    font-size: 0.9rem;
    color: #333;
}

/* MEDIUM */
@media screen and (min-width: 768px) {}

/* LARGE */
@media screen and (min-width: 992px) {}

/* XL SCREEN ----------------------------------------------------------------- LAPTOP*/
@media screen and (min-width: 1200px) {
    header {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: linear-gradient(135deg, #2c3e50, #34495e);
        color: whitesmoke;
        font-size: 25px;
        padding: 25px;
        box-shadow: 0px 5px 5px 0px rgba(86, 85, 85, 0.378);
    }

    header h1 {
        display: flex;
        font-size: 25px;
        font-weight: 200;
    }

    .menu {
        list-style-type: none;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: space-around;
        padding: 0;
        margin: 0;
    }

    .menu li {
        margin: 0;
        font-weight: bold;
    }

    .menu li a {
        text-decoration: none;
        font-size: 16px;
        padding: 28px 0;
        transition: background-color 0.3s ease-in, border-color 0.3s ease;
        background: rgba(255, 255, 255, 0.5);
        color: #1c4777;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(92, 92, 92, 0.5);
        border-bottom-left-radius: 0.1rem;
        border-bottom-right-radius: 0.1rem;

        -webkit-backdrop-filter: blur(0.8rem);
        backdrop-filter: blur(0.8rem);
    }

    .menu li a:hover {
        background: linear-gradient(to right, #598dc7, #7fb0e1);
        color: white;
    }

    iframe {
        width: 100%;
        height: 100%;
        border: dashed rgba(128, 128, 128, 0.059) 0.1px;
    }

    .social-icons {
        display: flex;
        margin-left: 15px;
    }

    .social-icons a {
        color: rgb(255, 249, 255);
    }

    .social-icons a:hover {
        color: rgb(52, 243, 201);
        transform: scale(1.1);
        transition: ease-out 0.3s;
    }

    .container-travel-map,
    .container-DJ {
        display: flex;
        margin: 0 auto;
        margin-top: 30px;
        width: 95%;
    }

    .container-web-projects {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 5rem;
    }

    .btn {
        display: unset;
        width: 35rem;
        font-size: 1.2rem;
        padding: 1.5rem 5rem;
        margin: 0.1rem;
        border-radius: 0.5rem;
        transition: background-color 0.3s ease, border-color 0.3s ease;
        background: linear-gradient(to top, rgba(234, 234, 234, 0.7), rgba(255, 255, 255, 0.7)),
            linear-gradient(to bottom, rgba(219, 219, 219, 0.7), rgba(255, 255, 255, 0.7));
        color: #1c4777;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid rgba(92, 92, 92, 0.5);
    }

    .btn:hover {
        background: linear-gradient(to right, #598dc7, #7fb0e1);
        color: white;
        transform: scale(1.01);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    }

    h2 {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    h3 {
        font-size: 2rem;
        text-align: center;
        padding: 10rem;
        margin: 1rem 0;
        display: inline-flex;
    }

    .column {
        margin: 0 2rem;
        column-count: 2;
    }

    h4 {
        padding: 0.5rem;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        font-size: 1.2rem;
    }

    h5 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }

    h6 {
        font-size: 2rem;
        margin: 1.2rem;
    }
}