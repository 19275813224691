/* Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.header-awards,
.footer-awards {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: linear-gradient(135deg, #2c3e50, #34495e);
    color: whitesmoke;
    font-size: 15px;
    padding: 10px;
    box-shadow: 0px 5px 5px 0px rgba(86, 85, 85, 0.378);
}

.footer-awards {
    position: fixed;
    bottom: 0;
    left: 0;
}

@keyframes icon-party-effect {
    0% {
        transform: scale(1) rotate(0deg);
        background-color: #ced7e182;
    }

    25% {
        transform: scale(1.2) rotate(0deg);
        background-color: #fef28996;
    }

    50% {
        transform: scale(1) rotate(0deg);
        background-color: #62f5ff92;
    }

    75% {
        transform: scale(1.2) rotate(0deg);
        background-color: #4caf4fa5;
    }

    100% {
        transform: scale(1) rotate(0deg);
        background-color: #ced7e100;
    }
}

.icon-party {
    animation: icon-party-effect 5s infinite;
}

.icon-party:active {
    transform: scale(0.7);
}

.icon-party:hover {
    transform: scale(1.5);
    transition: ease-in-out 0.4s;
}

.modal-awards {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.9);
    justify-content: center;
    align-items: center;
    z-index: 1000;
}


.modal-content-awards {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
    background-color: #2677f9;
}

.close-awards {
    position: absolute;
    top: 20px;
    right: 15px;
    color: rgb(255, 0, 85);
    font-size: 35px;
    font-weight: bold;
    cursor: pointer;
}

.close-awards:hover {
    color: rgb(241, 84, 255);
    transition: ease-out 0.3s;
    border: #3dff8e 1px solid;
    padding: 2px;
}

.hidden-awards {
    display: none;
}



.award:hover {
    transform: scale(1.02);
}



.award-description {
    padding: 1rem;
    text-align: left;
    flex: 1;
}

.award-description h2 {
    font-size: 1.5rem;
    color: #1e293b;
    margin-bottom: 1rem;
}

.award-description p {
    font-size: 1rem;
    color: #1e293b;
    line-height: 1.5;
}


#caption {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 43.75rem;
    text-align: center;
    color: #ccc;
    padding: 0.625rem 0;
    height: 9.375rem;
}

.modal-content-awards,
#caption {
    animation-name: zoom;
    animation-duration: 0.6s;
}

@keyframes zoom {
    from {
        transform: scale(0)
    }

    to {
        transform: scale(1)
    }
}

/* Responsive Design ------------------------------------------------------------------------ Mobile */
@media screen and (max-width: 1100px) {

    .header-awards {
        padding: 20px;

    }

    h1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 200;
    }




    .award {
        flex-direction: column;
    }

    .award {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        margin: 2rem 0;
        width: 90%;
        max-width: 75rem;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 1rem;
        box-shadow: 0px 1rem 2rem 0 rgba(0, 0, 0, 0.2);
        overflow: hidden;
        transition: transform 0.3s ease;
    }

    .award-image {
        width: 100%;
        object-fit: contain;
        padding: 1rem;
    }


    .gallery {
        width: 90%;
        /* Ensure the container is not too wide */
        display: flex;
        flex-direction: column;
        align-items: center;
        /* Centers content horizontally within the flex container */
        margin-bottom: 6rem;
        margin-left: auto;
        /* Ensure container is centered */
        margin-right: auto;
        /* Ensure container is centered */
    }


    .award-description p {
        font-size: 1rem;
    }
}

/* CSS Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Responsive Design - Tablet and Desktop ------------------------------------------------LAPTOP */
@media screen and (min-width: 1200px) {

    .header-awards {
        padding: 1rem;
    }


    .gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        /* Distributes items with equal space around them */
        margin-top: -1rem;
        margin-bottom: 5rem;
        padding: 5rem;
    }


    .award {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: calc(33.333% - 2rem);
        /* 3 columns with space between */
        margin-bottom: 2rem;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 1rem;
        box-shadow: 0px 1rem 2rem 0 rgba(0, 0, 0, 0.2);
        overflow: hidden;
        transition: transform 0.3s ease;
        cursor: pointer;
    }



    .award-image {
        width: 100%;
        object-fit: contain;
        padding: 1rem;
    }

    .award-description {
        padding: 2rem;
        flex-grow: 1;
    }

    h1 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 200;
    }

    main {
        padding: 0rem;
    }



    .award-description p {
        font-size: 1.2rem;
    }


}