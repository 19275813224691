.updown-button {
    position: fixed;
    bottom: 5rem;
    right: 5px;
    cursor: pointer;
    background: linear-gradient(135deg, #1cffa8, #2575fc);
    color: #fff;
    padding: 12px;
    border-radius: 5%;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
    font-size: 20px;
    transition: transform 0.3s;
    z-index: 10;
}

.updown-button:hover {
    transform: scale(1.1);
}

.updown-icon {
    font-size: 1.5em;
}